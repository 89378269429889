<template>
  <UiActionBar
    v-model="modelValue"
    :title="`${selectedIndexes.filter((s) => s).length} leads selected`"
    secondary-button-text="Cancel"
    @cancel="$emit('cancel')"
  >
    <div class="flex flex-row items-center gap-4">
      <UiTooltip name="move-to-pool" position-top>
        <template #activator>
          <button
            id="move_to_pool"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            @click="showMoveToPoolPopup"
          >
            <UiIcon name="filter"></UiIcon>
          </button>
        </template>
        <template #content>Release to pool </template>
      </UiTooltip>

      <UiTooltip name="assign" position-top>
        <template #activator>
          <button
            id="assign_agent"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            @click="showAssignAgentPopup"
          >
            <UiIcon name="user"></UiIcon>
          </button>
        </template>
        <template #content> Assign user to the selected leads</template>
      </UiTooltip>
      <UiTooltip v-if="showSendToQueue" name="send-queue" position-top>
        <template #activator>
          <button
            id="send_queue_lead"
            :disabled="closedLeadsAreSelected"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            :class="{ '!cursor-default !text-black-40': closedLeadsAreSelected }"
            @click="showSendToQueuePopup"
          >
            <UiIcon name="user-group"></UiIcon>
          </button>
        </template>
        <template #content>{{
          closedLeadsAreSelected ? 'Some closed leads are selected' : 'Send selected leads to the queue.'
        }}</template>
      </UiTooltip>
      <UiTooltip v-if="showCreateShadowLeadAction" name="create-shadow" position-top>
        <template #activator>
          <button
            id="create_shadow_lead"
            class="rounded-xl p-2 text-primary-20 transition hover:bg-white/10 hover:text-white"
            @click="checkShadowLeads"
          >
            <UiIcon name="shadow-copy-lead"></UiIcon>
          </button>
        </template>
        <template #content>Create shadow leads</template>
      </UiTooltip>
    </div>
  </UiActionBar>
</template>

<script setup lang="ts">
import { POPUPS } from '~/components/dynamic/maps'
import { useUiStore } from '~/store/ui'
import type { Lead, Tag } from '~/types'
import { PERMISSIONS, STAGES, TAGS } from '~/constants'

const modelValue = defineModel<boolean>()

const emits = defineEmits(['input', 'cancel'])

const uiStore = useUiStore()

type Props = {
  leads: Lead[]
  selectedIndexes: boolean[]
}
const props = defineProps<Props>()

const selectedLeads = computed(() => props.leads.filter((_: Lead, i: number) => props.selectedIndexes[i]))

const closedLeadsAreSelected = computed(() =>
  props.leads.some((lead: Lead, i: number) => props.selectedIndexes[i] && lead.stage?.code === STAGES.CLOSED)
)

const showCreateShadowLeadAction = computed(() => useHasPermissions([PERMISSIONS.CAN_CREATE_SHADOW_LEADS]))

const showSendToQueue = computed(() => useHasPermissions([PERMISSIONS.CAN_SEND_LEAD_TO_QUEUE]))

const showAssignAgentPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
    {
      selectedLeads: selectedLeads.value,
      showToast: true,
    },
    {
      input: () => {
        emits('cancel')
        modelValue.value = false
      },
    }
  )
}

const getIsShadowLead = (lead: Lead) => {
  return lead.tags.find((tag: Tag) => TAGS.SHADOW_COPY_LEAD === tag.code)
}

const checkShadowLeads = () => {
  const shadowLeads = selectedLeads.value.filter((l: Lead) => getIsShadowLead(l))
  const selectedNotShadowLeads = selectedLeads.value.filter((l: Lead) => !getIsShadowLead(l))
  if (shadowLeads.length === selectedLeads.value.length) {
    uiStore.showPopup(POPUPS.PIPELINE_LEAD_CANT_CREATE_SHADOW)
  } else if (shadowLeads.length) {
    uiStore.showPopup(
      POPUPS.PIPELINE_LEAD_CANT_CREATE_SHADOW_HAS_SHADOW,
      { selectedShadowLength: shadowLeads.length, selectedLength: selectedLeads.value.length },
      {
        input: () => showShadowLeadsPopup(selectedNotShadowLeads),
      }
    )
  } else {
    showShadowLeadsPopup(selectedNotShadowLeads)
  }
}

const showShadowLeadsPopup = (leads: Lead[]) => {
  uiStore.showPopup(POPUPS.PIPELINE_LEAD_CREATE_SHADOW, { selectedLeads: leads }, { input: () => emits('input') })
}

const showMoveToPoolPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_MOVE_TO_POOL,
    {
      selectedLeads: selectedLeads.value,
      showToast: true,
    },
    {
      input: () => {
        emits('input')
        modelValue.value = false
      },
    }
  )
}

const showSendToQueuePopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_SEND_TO_QUEUE,
    {
      selectedLeads: selectedLeads.value,
    },
    {
      input: () => {
        emits('input')
        modelValue.value = false
      },
    }
  )
}
</script>

<style scoped></style>
